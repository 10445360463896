<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addBannerLayoutsModal' hide-footer>
        <addBannerLayouts @closeAddBannerLayouts='toggleAddBannerLayouts()' @addBannerLayoutsSuccess='addBannerLayoutsSuccess()'></addBannerLayouts>
      </b-modal>
      <b-modal ref='editBannerLayoutsModal' hide-footer>
        <editBannerLayouts
          :editingBannerLayouts='currentBannerLayouts'
          @closeEditBannerLayouts='toggleEditBannerLayouts()'
          @editBannerLayoutsSuccess='editBannerLayoutsSuccess()'
        ></editBannerLayouts>
      </b-modal>
      <div class='row'>
        <h2>BannerLayouts</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddBannerLayouts()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addBannerLayouts from '../../components/addBannerLayouts';
import editBannerLayouts from '../../components/editBannerLayouts';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'html',
                field: 'html',
                sort: 'asc',
              },
              {
                label: 'css',
                field: 'css',
                sort: 'asc',
              },
              {
                label: 'JsonPlaceholders',
                field: 'JsonPlaceholders',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addBannerLayoutsOpen: false,
      editBannerLayoutsOpen: false,
      currentBannerLayouts: {}
    };
  },
  components: {
    addBannerLayouts,
    editBannerLayouts,
    Datatable,
  },
  created: function() {
    this.getBannerLayouts();
    this.addBannerLayoutsOpen = false;
    this.editBannerLayoutsOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getBannerLayouts() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getBannerLayouts', payload);
    },
    editItem(BannerLayouts) {
      this.toggleEditBannerLayouts();
      this.currentBannerLayouts = BannerLayouts;
    },
    toggleAddBannerLayouts() {
      if(this.addBannerLayoutsOpen)
      {
        this.$refs.addBannerLayoutsModal.hide()
      }
      else{
        this.$refs.addBannerLayoutsModal.show()
      }
      this.addBannerLayoutsOpen = !this.addBannerLayoutsOpen;
    },
    addBannerLayoutsSuccess() {
      this.toggleAddBannerLayouts();
      miniToastr['success']('BannerLayouts Added', 'Success', 1000, null);
      this.getBannerLayouts();
    },
    toggleEditBannerLayouts() {
      if(this.editBannerLayoutsOpen)
      {
        this.$refs.editBannerLayoutsModal.hide()
      }
      else{
        this.$refs.editBannerLayoutsModal.show()
      }
      this.editBannerLayoutsOpen = !this.editBannerLayoutsOpen;
    },
    editBannerLayoutsSuccess() {
      this.toggleEditBannerLayouts();
      miniToastr['success']('BannerLayouts Edited', 'Success', 1000, null);
      this.getBannerLayouts();
    }
  }
};
</script>
<style scoped>
.BannerLayoutsThumbnail {
  height: 50px;
}
</style>
